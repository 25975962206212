.video {
    position: relative;
    padding-bottom: 56.25%; /*16:9*/
    height: 0; 
    background: black;
    &:hover {
        .video__info {
            opacity:1;
          
        } 
    }
    &.playing {
        z-index: 11;
        .video__close {
            display: block;
        }
    }
    &__info {
        position: absolute;
        bottom:0;
        text-transform: uppercase;
        padding: 1rem;
        @include media-breakpoint-up(sm) {
            opacity: 0;
            display: block;
        }
        display: none;
        transition: opacity .2s linear;
    } 
    &__close {
        display: none;
        pointer-events: none;
        position: absolute;
        background: #000;
        width: 2rem;
        height: 2rem;
        right:0;
        bottom: 100%;
        &:after {
            content: '';
            position: absolute;
            width: 1.5rem;
            height: 1.5rem;
            left: 55%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-image: url(../images/close.png);
            background-size: contain;
            background-position: center;
        }
    }

    .video__overlay {
        &:hover {
            background-size: 105%;
            .video__info {
                display: block;
            } 
        }
        &__dim {
            text-transform: uppercase;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000, $alpha: .3);
            padding:1rem;
        }
        transition: all 0.3s;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        background-position: center;
        background-size: 100%;
    }
    cursor: pointer;
    iframe,object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
    }
  }
   
