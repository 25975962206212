/*

Kirby Starterkit

Author: Bastian Allgeier / Sascha Lack
URL:    http://getkirby.com
Email:  support@getkirby.com

*/

/* vendor */
@import './bootstrap/bootstrap.scss';



/* compnents */
@import "./components/home.scss";
@import "./components/artist.scss";
@import "./components/video.scss";
@import "./components/project-grid.scss";
@import "./components/header.scss";
@import "./components/footer.scss";


/* Source Sans Pro
-------------------------------------------------- */
@font-face {
  font-family: 'Aktiv Grotesk Corp';
  src: url('../fonts/AktivGroteskCorp-Regular.woff2') format('woff2'),
      url('../fonts/AktivGroteskCorp-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


/* Reset
-------------------------------------------------- */
*, *:before, *:after {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, main, nav, section, summary {
  display: block;
}


/* Pix
-------------------------------------------------- */
img {
  display: block;
  max-width: 100%;
}


/* Links
-------------------------------------------------- */
a {
  color: #fff;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
}
a:hover {
  color: red;
}


/* Clearfix
-------------------------------------------------- */
.cf:after {
  content: "";
  display: table;
  clear: both;
}


/* Site
-------------------------------------------------- */
html {
  height: 101%;
  font-family: "Aktiv Grotesk Corp" !important;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow: hidden;
  @include media-breakpoint-up(sm) {
    overflow: unset;
  }
}
body {
  font-family: "Aktiv Grotesk Corp" !important;
  margin: 0 auto;
  font-size: 16px;
  width:100%;
  line-height: 1.5em;
  background:rgb(37,43,40);
  color: #fff;
  overflow-x: hidden;
}

a:hover {
  text-decoration: none;
}
/* Header
-------------------------------------------------- */


/* Navigation
-------------------------------------------------- */
.menu a {
  display: block;
  color: #222;
}
.menu a:hover {
  color: #fff;
  text-decoration: none;
}
.menu .active {
  color: #fff;
}
.menu li {
  list-style: none;
}
.menu > li {
  position: relative;
}
.menu > li > a {
  padding: .35em 0;
  border-top: 1px solid #ddd;
  font-weight: 600;
}

.submenu {
  position: absolute;
  z-index: 1;
  display: none;
  left: 1em;
}
.submenu:before {
  position: absolute;
  z-index: -1;
  content: '';
  top: -.5em;
  left: 0;
  border-top: .5em solid transparent;
  border-bottom: .5em solid transparent;
  border-left: .5em solid #222;
}
.submenu li {
  border-bottom: 1px solid #444;
}
.submenu a {
  padding: .5em 1.5em;
  font-size: .9em;
  font-weight: 300;
  color: #fff;
  background: #222;
  white-space: nowrap;
}
.submenu a:hover {
  background: #333;
  color: #fff;
}

@media screen and (min-width: 40em) {
  .logo {
    float: left;
  }
  .menu {
    float: right;
    margin-right: -1em;
  }
  .menu > li {
    float: left;
  }
  .menu > li > a {
    padding: .35em 1em;
    border: 0;
  }
  .menu > li:hover .submenu {
    display: block;
  }
}


/* Headings
-------------------------------------------------- */
h1 {
  font-size: 1.2rem;
  letter-spacing: 3px;
  @include media-breakpoint-up (sm) {
      font-size: 3.5rem;
  }
  @include media-breakpoint-only(md) {
      font-size: 2.5rem;
  }
  line-height: 1em;
  text-transform: uppercase;
}

.about {
  @include media-breakpoint-up (sm) {
    margin: 4rem auto;
  }
  margin: 1.5nrem auto;
  h1 {
    @include media-breakpoint-up (sm) {
      margin-bottom: 3rem;
    }
    margin-bottom: 1rem;
      
    
    text-transform: none;
    @include media-breakpoint-up (sm) {
      font-size: 2.5rem;
  }
  }
}

h2 {
  font-size: 2em;
  line-height: 1.25em;
  font-weight: 300;
  margin-bottom: .5em;
  color: red;
}
h3 {
  font-size: 1.4em;
  line-height: 1.5em;
  font-weight: 400;
  margin-bottom: .5em;
}
h4 {
  font-size: 1.125em;
  font-weight: 600;
  line-height: 1.5em;
  margin-bottom: .5em;
  color: red;
}
h1 a, h2 a, h3 a, h4 a {
  color: inherit;
  border: 0 !important;
}

ul {
  margin:0;
}



/* Main
-------------------------------------------------- */

.main hr {
  margin: 3em 0;
  height: 2px;
  background: #ddd;
}
.main p,
.main figure,
.main ul,
.main ol {
  margin-bottom: 1.5em;
}
.main a {
  border-bottom: 2px solid #ddd;
}
.main a:hover {
  border-color: #222;
}

/* Text blocks */
.text ul,
.text ol {
  margin-left: 1em;
}

/* Project meta
-------------------------------------------------- */
.meta {
  margin-bottom: 1.5em;
}
.meta li {
  float: left;
  list-style: none;
  margin-right: 2em;
  color: red;
}
.meta li b {
  font-weight: 400;
  color: #000;
  padding-right: .25em;
}


/* Project teaser
-------------------------------------------------- */
.teaser {
  list-style: none;
}
.teaser li {
  margin-bottom: 1.5em;
  list-style: none;
}
@media screen and (min-width: 50em) {
  .teaser li {
    float: left;
    width: 30%;
    margin-left: 5%;
  }
  .teaser li:first-child {
    margin-left: 0;
  }
}


/* Next/Prev nav
-------------------------------------------------- */
.nextprev {
  padding: 1em 0;
}
.nextprev a {
  border: 0;
}
.nextprev .prev {
  float: left;
}
.nextprev .next {
  float: right;
}


/* Footer
-------------------------------------------------- */
.footer {
  @include media-breakpoint-up(sm) {
    padding: 2rem 2rem 2rem;
  } 
  padding: 1rem 1rem 0rem;
  font-size: .8em;
}
.copyright {
  float: left;
}
.colophon {
  float: right;
}
.colophon a b {
  color: red;
  padding-left: .25em;
}
