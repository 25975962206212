.project-grid {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    
    .project-grid__overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(#000, 0.8);
        top:0;
        display:none;
        cursor: crosshair;
        z-index: 10;
    }

    &__col {
        flex-basis: 100%;
        @include media-breakpoint-up(sm) {
            flex-basis: 50%;
        }
        cursor: pointer;
    }
    
}