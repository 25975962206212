.no-scroll {
    overflow: hidden;
}
.header {
    &__btn {
        margin-right: 0.5rem;
    }
    &__close {
        position: absolute;
        right: 32px;
        
        width: 32px;
        height: 32px;
        top: -15px;
        right: 2px;
        z-index: 1000;
        display: none;
      
        &.open{
            display: block;
        }
      }
      &__close:before, &__close:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 3px;
        background-color: white;
      }
      &__close:before {
        transform: rotate(45deg);
      }
      &__close:after {
        transform: rotate(-45deg);
      }
    nav {
        text-transform: uppercase;
    }
    img {
        width: 80px;
        z-index: 10000;
        position: relative;
    }
    display: inline-flex;
    // @include media-breakpoint-up(sm) {
    //     align-items: flex-start;
    // }
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: .5rem;
    padding-right: .5rem;
    position: relative;
    border-bottom: 1px solid #ffffff26;

    &.is-home {
        border: none;
        position: absolute;
        top: 0;
    }
    &__mobilemenu {
        position: fixed;
        width: 100%;
        height: 100vh;
        background:rgba(37,43,40, 0.9);
        top: 0;
        display: none;
        z-index: 100;
        justify-content: center;
        align-items: center;
        &.open {
            display: flex;
        }
        &__list {
            display: flex;
            flex-direction: column;
            text-align: center;
            h3 {
                text-transform: uppercase;
                margin-bottom: .5rem;
            }
        }
    }
    &__hamburger {
        z-index: 10000;
        position: relative;
        div {
            width: 33px;
            height: 3px;
            background-color: white;
            margin: 6px 0;
        }
        cursor: pointer;
        opacity: 1;
        @include media-breakpoint-up(sm) {
          opacity: 0;
        }  
    }

    .header__nav {
        text-transform: uppercase;
        width:100%;
        max-width: 1000px;
        text-align: center;
        padding:1rem 0;
        flex-shrink: 1;
        flex-wrap: wrap;
        justify-content: center;
        display: block;
        align-items: flex-start;

        &__2 {
            display: block;
            padding: 0.5rem;
            padding-left: 0;

            @include media-breakpoint-up(sm) {
                padding: 1rem;
            }
        }
        
        a {
            word-break: keep-all;
            position: relative;
            margin:0 .5rem;
            display: block;
        }

        a[aria-current="page"]{
            color:red;
        }
    }

    h1 {
        margin:0;
        font-size: 2em ;
    }
}