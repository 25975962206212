.home {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
        margin: -1rem;
        @include media-breakpoint-up(sm) {
            margin: -2rem;
        }
        font-size: 1rem;
    }
    h1 {
        margin-bottom: .5rem;
    }
    img {
        @include media-breakpoint-up(sm) {
            max-width: 540px;
        }
        max-width: 300px;
        width: 100%;
        margin:0 auto; 
    }


    .directors {
        margin-top: 5rem;
        max-width: 330px;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-up(sm) {
            max-width: 900px;
            margin-top: 10rem;
        }

        li {
            display: inline-block;
            font-size: 0.9rem;
            position: relative;
            text-transform: uppercase;
            line-height: unset;

        
            @include media-breakpoint-up(sm) {
                font-size: 1.7rem;
                line-height: 1.5;
            }

            .dot {
                display: inline-block;
                transform: translateY(-3px);
                margin: 0 0.5;
                font-size: 6px;
                color: #fff;

                @include media-breakpoint-up(sm) {
                    font-size: 8px;
                    transform: translateY(-6px);
                }
            }

            &:last-child {
                .dot {
                    display: none;
                }
            }
        }
    }
}